import React from 'react'
import { useRouter } from 'next/navigation';

const Footer = () => {
    const router = useRouter();
  return (
    <div className="w-full bg-[#f2f2f2]">
    <div className="max-w-screen-xl mx-auto px-4 md:px-8 lg:px-0">
        {/* Info Section */}
        <div className='flex flex-col sm:flex-row-reverse items-center justify-center w-full'>
            {/* Social Icons */}
            <div className="mt-4 hidden justify-center items-center gap-2 lg:justify-start">
                <img alt="facebook" src="/facebook.svg" className="w-auto h-auto cursor-not-allowed" />
                <img alt="instagram" src="/instagram.svg" className="w-auto h-auto cursor-not-allowed" />
                <img alt="twitter" src="/twitter.svg" className="w-auto h-auto cursor-not-allowed" />
                <img alt="linked_in" src="/linked_in.svg" className="w-auto h-auto cursor-not-allowed" />
            </div>
            </div>
        </div>
</div>
  )
}

export default Footer
